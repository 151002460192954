/*
 * @Description: 停车时长
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2022-01-10 17:13:15
 * @LastEditors: zhoucheng
 */
import moment from 'moment'
export default function parkStopTime (minTime, maxTime) {
  const m1 = moment(minTime)
  const m2 = moment(maxTime)
  let years = moment.duration(m2 - m1)._data.years
  let months = moment.duration(m2 - m1)._data.months
  let days = moment.duration(m2 - m1)._data.days
  let hours = moment.duration(m2 - m1)._data.hours
  let minutes = moment.duration(m2 - m1)._data.minutes
  let seconds = moment.duration(m2 - m1)._data.seconds
  years === 0 ? years = '' : years += '年'
  months === 0 ? months = '' : months += '月'
  days === 0 ? days = '' : days += '天'
  hours === 0 ? hours = '' : hours += '小时'
  minutes === 0 ? minutes = '' : minutes += '分钟'
  seconds === 0 ? seconds = '0秒' : seconds += '秒'

  const parkStopTime = years + months + days + hours + minutes + seconds
  return parkStopTime
}

// 判断两段时间的时间间隔不超过三天
export function timeDuration (currentTime, lastTime) {
  const timeBegin = moment(lastTime)
  const timeEnd = moment(currentTime)
  const milliTime = moment.duration(timeEnd - timeBegin)._milliseconds
  const timeDuration = milliTime / 1000
  return timeDuration
}
